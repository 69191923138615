import bind from 'bind-decorator';
import { EventEmitter2 } from 'eventemitter2';
import { EnhancedEventEmitter2 } from '../base/iMsSignaling';
import { MsClient } from '../base/msClient';
import { ProducerInfo } from '../base/producerInfo';
import { NotConnectedError } from '../../rabbitmq/customErrors';
import { MediaTrack } from './mediaTrack';
import { Logger } from '../../../helpers/logger';
import { UserMediaError } from '../../../helpers/errors/userMediaError';
import { computed, makeObservable, observable, reaction } from 'mobx';
import { LocalEndpointState } from '../../state/localEndpointState';


export class LocalEndpoint extends EnhancedEventEmitter2 {

    protected producerInfos: ProducerInfo[] = [];

    // overrides the inherited ones
    public readonly EVENT_CONNECTED: string[] = ['MsClient', 'connected']
    public readonly EVENT_DISCONNECTED: string[] = ['LocalEndpoint', 'disconnected']

    public static readonly EVENT_TRACKS = 'tracks';
    public static readonly EVENT_STOPPED_TRACKS = 'stopped_tracks';
    protected readonly logger: Logger;

    @computed
    public get connected(): boolean {
        return this.msClient.connected
    }

    public get id(): string {
        return 'localEndpoint'
    }

    /**
     * sharedData: always shared with all connected parties
     */
    constructor(
        public readonly msClient: MsClient, 
        public readonly sharedData: any) {
            super()
            makeObservable(this)
            this.logger = new Logger('LocalEndpoint')
            this.msClient.onDisconnected(this.onMsClientDisconnected)
            this.msClient.onConnectedAsync(this.onMsClientConnected)
            // reaction(() => this.localEndpointState.audioOutputDeviceId,
            //     (value, prevValue, reaction) => {
            //         handleAudioOutputDevice(value, "environment")
            //     })
    }
    
    @bind
    private onMsClientDisconnected() {
        this.logger.debug('LocalEndpoint.onMsClientDisconnected()')
        try {
            this.stop()
        } catch (e) {
            if (e instanceof NotConnectedError) {
                // stop() above tries to inform remote parties about this endpoint stopping, but since we were disconnected
                // I didn't really expect that we can do that
                // btw remote parties should be informed by the presence exchange about the disconnect
            }
        }
        this.emit(this.EVENT_DISCONNECTED, this)
    }
    
    @bind
    private onMsClientConnected() {
        this.emit(this.EVENT_CONNECTED)
    }

    private stopProducers(pis: ProducerInfo[]): ProducerInfo[] {

        if (pis.length === 0) return this.producerInfos

        let stoppedTracks: MediaStreamTrack[] | undefined
        for(const producerInfo of pis) {
            // this also removes from from msClient
            stoppedTracks = this.msClient.stop(producerInfo.id)
        }

        this.producerInfos = this.producerInfos.filter(p => pis.find(x => x.id === p.id) === undefined)

        stoppedTracks && this.emit(LocalEndpoint.EVENT_STOPPED_TRACKS, stoppedTracks)

        return this.producerInfos
    }
   
    public async produceDefaultMedia(video: boolean = true, audio: boolean = true, data: boolean = true): Promise<ProducerInfo[]> {
        
        const tracks: MediaTrack[] = []
        
        if (audio || video) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video, audio })
                
                if (audio) {
                    const audioTrack = stream.getAudioTracks()[0]
                    tracks.push(new MediaTrack(audioTrack, 'main audio'))
                }
                if (video) {
                    const videoTrack = stream.getVideoTracks()[0]
                    tracks.push(new MediaTrack(videoTrack, 'main video'))
                }
            } catch (e) {
                this.logger.error('Error requesting getUserMedia', e)
                throw new UserMediaError()
            }
        }
        return await this.produceMedia({ mediaTracks: tracks, data: { name: 'main data'}})
    }

    public async produceMedia(props: { mediaTracks?: MediaTrack[] | null, data?: { name: string }, opts?: { opusDtx: boolean } } = { mediaTracks: null, data: undefined }): Promise<ProducerInfo[]> {
        this.logger.debug(`produceMedia`, props)
        const result: ProducerInfo[] = [];

        if ((!props.mediaTracks || props.mediaTracks.length === 0) && !props.data) 
            throw new Error('You have to specify at least one track or data to send')

        if (props.mediaTracks) {
            for (const mediaTrack of props.mediaTracks) {
                const producerId = await this.msClient.produce(mediaTrack.track, mediaTrack.name, false, props.opts)
                const producerInfo = new ProducerInfo(mediaTrack.name, mediaTrack.track.kind, producerId, mediaTrack);
                result.push(producerInfo)
            }
        }

        if (props.data) {
            const dataProducerId = await this.msClient.produceData(props.data.name)
            const producerInfo = new ProducerInfo(props.data.name, 'data', dataProducerId, null);
            result.push(producerInfo)
        }
        
        this.producerInfos.push(...result)
        const mediaTracks = this.getTracks(this.producerInfos)
        this.emit(LocalEndpoint.EVENT_TRACKS, mediaTracks)

        return result
    }

    public async replaceTrack(producerId: string, mediaTrack: MediaTrack | null): Promise<void> {
        return this.msClient.replaceTrack(producerId, mediaTrack?.track || null)
    }
    
    public stop(pis: ProducerInfo[] | undefined = undefined): ProducerInfo[] {
        if (pis) {
            return this.stopProducers(pis)
        } else {
            return this.stopProducers(this.producerInfos)
        }
    }

    public sendData(data: any[], dataProducerId?: string) {
        this.msClient.send(JSON.stringify(data), dataProducerId)
    }

    public getTracks(pis: ProducerInfo[]): MediaStreamTrack[] {
        const producerIds = pis.map(pi => pi.id)
        return this.msClient.getTracks(producerIds)
    }
}