import bind from "bind-decorator";
import { IGroupSignaler, IMsSignaling, IPstnSignaling, ISmsSignaling, IUserServerSignaling } from "../msClient/base/iMsSignaling";
import { formatId, MqClient } from "./mqClient";
import Stomp from 'stompjs';
import { Sms } from "./sms";
import { PstnEvent } from "./pstnEvent";
import { Signaler } from "./signaler";
import { EventEmitter2 } from "eventemitter2";
import { Logger } from "../../helpers/logger";
import { PresenceService } from "./presenceService";
import { computed, makeObservable } from "mobx";

const HELLO_MSG = { event: "Hello!" }
const HELLO_BACK = "Hello to you too!"

export class MsSignaler extends Signaler implements IMsSignaling {
    
    // overrides the inherited ones
    public readonly EVENT_CONNECTED: string[] = ['MsSignaler', 'connected']
    public readonly EVENT_DISCONNECTED: string[] = ['MsSignaler', 'disconnected']
    logger: Logger;
    
    protected _sfuConnected: boolean = false;

    @computed
    public override get connected(): boolean {
        return this.mqClient.connected && this._sfuConnected
    }

    constructor(mqClient: MqClient, 
        public readonly presenceService: PresenceService,
        public readonly sfuId: string) {
        super(mqClient, 
            `q.${mqClient.user_id}.${mqClient.conn_id}.ms`, 
            `${mqClient.exchange}/rems.${sfuId}.${mqClient.id}`,
            `${mqClient.exchange}/ms.${mqClient.id}`)
        makeObservable(this)
        
        this.logger = new Logger('MsSignaler')

        this.presenceService.on(PresenceService.ANY_ENDPOINT_CONNECTED, this.remoteEndpointConnected)
            // this.mqClient.on(MqClient.EVENT_CONNECTED, this.onConnected)
    }
    
    @bind
    protected remoteEndpointConnected(data: any) {
        if (data.sid.startsWith("sfu")) {
            this.onSfuConnected(data)
        }
    }
        
    @bind
    protected onSfuConnected(data: any) {
        this.logger.debug('Sfu has connected', data)
        this.presenceService.once([PresenceService.EVENT_ENDPOINT_DISCONNECTED, ...data.aid], this.onSfuDisconnected)
        // super.onMqClientConnected()
        this.on(HELLO_BACK, this.onHelloBack)
        this.send('new', HELLO_MSG)
    }

    @bind
    protected onSfuDisconnected(data: any) {
        this.logger.debug('Sfu has disconnected', data)
        this._sfuConnected = false
        this.emit(this.EVENT_DISCONNECTED)
    }       

    @bind
    private onHelloBack(content: any) {
        this.logger.debug('onHelloBack', content);
        // const j = JSON.parse(s)
        if (content.user_id === this.mqClient.user_id && content.conn_id === this.mqClient.conn_id) {
            this.logger.debug('Connected!')

            this._sfuConnected = true
            this.emit(this.EVENT_CONNECTED)
        } else {
            this.logger.error(`The ids don't match what I expected`)
        }
    }
}
    
export class GroupSignaler extends Signaler implements IGroupSignaler {

    logger: Logger;

    constructor(public readonly mqClient: MqClient) {
        super(mqClient, 
            `q.${mqClient.user_id}.${mqClient.conn_id}.group`, 
            `${mqClient.exchange}/g.#`,
            `${mqClient.exchange}/g.${mqClient.id}`)
        this.logger = new Logger('GroupSignaler')
    }

    @bind
    protected onMessage(msg: Stomp.Message) {
        this.logger.debug(`GroupSignaler.onMessage()`, msg)
        const s = msg.body.toString()
        const j = JSON.parse(s)
        const destinations = (msg.headers as any).destination.split('/');
        const sender = destinations[destinations.length - 1].split('.')
        const s_user_id = sender[1]
        const s_conn_id = sender[2]
        const senderId = formatId(s_user_id, s_conn_id)
        if (senderId === this.mqClient.id) {
            if (this.deliverOwnMessages || j.event === 'paymentMethodRequired' ) {
                this.logger.warn('DELIVEROWNGROUPMSG is set to true, we are delivering own message', msg)
            } else {
                return
            }
        }
        this.emit([s_user_id, s_conn_id, j.event], senderId, j.content)
    }
}

